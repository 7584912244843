<template>
  <app-module-view>
    <template slot="body">
      <div>
        <div class="m-b-10">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-right m-r-5">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('beUser.save') }}
                </button>
                <app-button-close route-name="beUserKpi_list"></app-button-close>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card">
              <div class="card-header">{{ beUser.firstName }} {{ beUser.lastName }}</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.articleCnt"
                      id="beUserKpi_articleCnt"
                      :label="$t('kpi.kpi.articleCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.articleEditCnt"
                      id="beUserKpi_articleEditCnt"
                      :label="$t('kpi.kpi.articleEditCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.imagesCnt"
                      id="beUserKpi_imageCnt"
                      :label="$t('kpi.kpi.imagesCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.viewCnt"
                      id="beUserKpi_pageViewCnt"
                      :label="$t('kpi.kpi.articleView.viewCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.photostoryCnt"
                      id="beUserKpi_photostoryCnt"
                      :label="$t('kpi.kpi.articleType.photostoryCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.quizCnt"
                      id="beUserKpi_quizCnt"
                      :label="$t('kpi.kpi.articleType.quizCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.videoCnt"
                      id="beUserKpi_videoCnt"
                      :label="$t('kpi.kpi.videosCnt')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      type="number"
                      v-model="beUser.kpi.videoViewCnt"
                      id="beUserKpi_videoViewCnt"
                      :label="$t('kpi.kpi.videoViewCnt')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import BeUserModel from '../../model/beUser/BeUser'
import Input from '../../components/form/inputs/Input'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'BeUserKpiEditView',
  data () {
    return {
      beUser: this._.cloneDeep(BeUserModel)
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonClose: ButtonClose
  },
  methods: {
    getBeUser () {
      this.$store.dispatch('beUserKpi/fetchOne', this.$route.params.id)
        .then(() => {
          this.beUser = this.$store.getters['beUserKpi/detail']
        })
    },
    async save () {
      this.$store.dispatch('beUserKpi/update', this.beUser)
        .then(() => {
          if (this.$store.getters['beUserKpi/error'] === null) {
            this.getBeUser()
            NotifyService.setSuccessMessage(this.$t('beUser.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['beUserKpi/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getBeUser()
  }
}
</script>
